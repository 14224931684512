import React from "react";
import spacemanSrc from "../../../static/2020/images/impactcall/spaceman.png";
import certSrc from "../../../static/2020/images/impactcall/cert.svg";
import {
  StyledPrices,
  StyledPricesCert,
  StyledPricesItem,
  StyledPricesItems,
  StyledPricesItemsGroup,
} from "./style";

export const Prices = () => (
  <StyledPrices id="premio">
    <h2>I premi</h2>
    <p>
      Il concorso si propone di finanziare, per davvero, i tre migliori
      progetti, per dare un boost significativo alla loro realizzazione. Per
      questo 3Bee ®  ha individuato i seguenti premi:
    </p>
    <p style="font-size: 12px;">*una volta sottomesso non è possibile cambiare o modificare la candidatura. Variazioni prevedono la squalifica.</p>
    <StyledPricesItems>
      <StyledPricesItemsGroup>
        <StyledPricesItem first>
          <img src={spacemanSrc} loading="lazy" />
          <h3>
            PRIMO
            <br />
            CLASSIFICATO
          </h3>
          <strong>5000€</strong>
        </StyledPricesItem>
        <StyledPricesItem second>
          <img src={spacemanSrc} loading="lazy" />
          <h3>
            SECONDO
            <br />
            CLASSIFICATO
          </h3>
          <strong>1000€</strong>
        </StyledPricesItem>
      </StyledPricesItemsGroup>
      <StyledPricesItem thirth>
        <img src={spacemanSrc} loading="lazy" />
        <h3>
          TERZO
          <br />
          CLASSIFICATO
        </h3>
        <strong>500€</strong>
      </StyledPricesItem>
    </StyledPricesItems>
    <h2 style="font-size: 30px;">
      50k di Investimento | Power By 3Bee
    </h2>
    <p style="padding-bottom: 20px; border-bottom: 1px solid #FFB000; max-width: 1248px">
      Un investimento fino a 50k solo all'idea che supporta l'ambiente, le comunità tramite innovazioni tecnologiche e sociali.
    </p>
    <StyledPricesCert>
      <h2>
        <img src={certSrc} loading="lazy" />
        Best Tech Award | Power By Uqido
      </h2>
      <p>
        Una grande idea oggi può essere un’idea tech.
        <br />
        La tecnologia può avere un impatto sulle nostre società, basta volerlo. Per questo il nostro Main Sponsor Uqido ha deciso di assegnare un riconoscimento al progetto che si distinguerà per la Migliore Idea Tech.
        Quando la Tecnologia è al servizio delle Persone, allora diventa davvero Utile. Se non ci migliora, perché adottarla?
        Vuoi portare un vero Cambiamento grazie alla Tecnologia? Uqido ti premia, non perdere l’occasione!
      </p>
    </StyledPricesCert>
  </StyledPrices>
);
